import React, { useRef } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

export default function SmsCode({
  title,
  subtitle,
  footer,
  phone,
  loading,
  actions,
}: {
  title: string;
  subtitle: string;
  footer?: string;
  phone?: string;
  loading: boolean;
  actions: { resend: () => void; signIn: (code: string) => void };
}) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const inputRefs = useRef(new Array(6).fill(''));

  function concatenateCode(): string {
    return inputRefs.current.map((irc: { value: string }) => irc.value).join('');
  }

  function handleChange(): void {
    const code = concatenateCode();
    if (code.length === 6) {
      actions.signIn(code);
    }
  }

  function onPaste(event: { preventDefault: () => void; clipboardData: { getData: (arg0: string) => string } }): void {
    event.preventDefault();
    const pasted = event.clipboardData.getData('text/plain');
    const segments = pasted.split('');
    segments.slice(0, 6).forEach((digit: string, idx: number) => {
      inputRefs.current[idx].value = digit;
    });
    handleChange();
  }

  function moveFocus(index: number): void {
    if (inputRefs.current[index].value.length === 1) {
      if (index !== 5) {
        inputRefs.current[index + 1].focus();
      }
    }
    handleChange();
  }

  return (
    <>
      {Boolean(loading) && (
        <Grid container justifyContent="center" alignItems="center" direction="column" p={2}>
          <CircularProgress />
          <Typography>Loading...</Typography>
        </Grid>
      )}

      <Paper
        sx={{
          py: 4,
          px: isSmall ? 4 : 6,
          borderRadius: '30px',
          maxWidth: '700px',
          gridTemplateColumns: isSmall ? 'minmax(300px, 90%)' : '1fr 300px',
          pb: 4,
          pt: isSmall ? 4 : 8,
          textAlign: 'center',
        }}
        elevation={isSmall ? 0 : 2}>
        <Typography variant="h2" component="h1" mb={2}>
          {title}
        </Typography>

        <Grid mt={6} mb={6} container justifyContent="center">
          <TextField
            id="code1"
            inputProps={{
              onPaste,
              onChange: () => {
                moveFocus(0);
              },
              maxLength: 1,
              style: { textAlign: 'center' },
            }}
            inputRef={(ref) => {
              inputRefs.current[0] = ref;
            }}
            sx={{ width: '50px', mx: 0.5 }}
          />
          <TextField
            id="code2"
            inputProps={{
              // onPaste,
              onChange: () => {
                moveFocus(1);
              },
              maxLength: 1,
              style: { textAlign: 'center' },
            }}
            inputRef={(ref) => {
              inputRefs.current[1] = ref;
            }}
            sx={{ width: '50px', mx: 0.5 }}
          />
          <TextField
            id="code3"
            inputProps={{
              onPaste,
              onChange: () => {
                moveFocus(2);
              },
              maxLength: 1,
              style: { textAlign: 'center' },
            }}
            inputRef={(ref) => {
              inputRefs.current[2] = ref;
            }}
            sx={{ width: '50px', mx: 0.5 }}
          />
          <TextField
            id="code4"
            inputProps={{
              onPaste,
              onChange: () => {
                moveFocus(3);
              },
              maxLength: 1,
              style: { textAlign: 'center' },
            }}
            inputRef={(ref) => {
              inputRefs.current[3] = ref;
            }}
            sx={{ width: '50px', mx: 0.5 }}
          />
          <TextField
            id="code5"
            inputProps={{
              onPaste,
              onChange: () => {
                moveFocus(4);
              },
              maxLength: 1,
              style: { textAlign: 'center' },
            }}
            inputRef={(ref) => {
              inputRefs.current[4] = ref;
            }}
            sx={{ width: '50px', mx: 0.5 }}
          />
          <TextField
            id="code6"
            inputProps={{
              onPaste,
              onChange: () => {
                moveFocus(5);
              },
              maxLength: 1,
              style: { textAlign: 'center' },
            }}
            inputRef={(ref) => {
              inputRefs.current[5] = ref;
            }}
            onChange={() => handleChange}
            sx={{ width: '50px', mx: 0.5 }}
          />
        </Grid>

        <Typography m={2}>{subtitle}</Typography>
        <Typography m={2}>{phone}</Typography>

        <Box>
          <Divider sx={{ my: 2 }} />
          <Typography>Haven&apos;t received your code?</Typography>
          {!loading && (
            <Button variant="text" onClick={actions.resend}>
              Resend my code
            </Button>
          )}

          <Typography m={2}>{footer}</Typography>
        </Box>
      </Paper>
    </>
  );
}

SmsCode.defaultProps = {
  footer: '',
  phone: '',
};
